<template>
    <body>

        <!-- preloader -->
        <Loader> </Loader>
        <!-- preloader -->
        <div class="frame">
            <div class="site-wrapper overflow-hidden">

                <!-- Header Section -->
                <Front-Header> </Front-Header>
                <div class="for-desktop">

                    <!-- Main Start-->
                    <section class="hero_section new-changes">
                        <img :src="pages.image" style="position: absolute;height: 100%;width: 100%;" :alt="pages.title">
                        <div class="container">
                            <h1 data-aos="fade-right" data-aos-duration="1200">{{ pages.title }}</h1>
                            <p data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200">{{
                                pages.short_description
                            }}</p>
                            <router-link to="/search/?search=" class="btn btn-primary btn-yallowtbn" data-aos="fade-up"
                                data-aos-duration="1200" data-aos-delay="400">Explore for free</router-link>
                        </div>
                    </section>

                    <inspired></inspired>
                    <subscription id="subscription"></subscription>
                    <features></features>
                    <topintro></topintro>
                    <our-app></our-app>
                    <newsletter></newsletter>
                    <!-- Main End-->

                </div>

                <div class="for_mobile_view">
                    <!-- <header class="header">
                <p class="user_name">Hi, There</p>
                <div class="search">
                    <form action="">
                        <div class="input_group">
                            <span class="material-icons">search</span>
                            <input type="text" name="" placeholder="Search by place,name & more" id="">
                        </div>
                        <div class="location">
                            <span>Dubai</span>
                            <span class="material-icons">keyboard_arrow_down</span>
                        </div>
                    </form>
                </div>
            </header>

          -->

                    <div class="top_slider_section">
                        <VueSlickCarousel class="slider-m-12d" :arrows="false" :dots="false">
                            <div v-for="subscription in subscriptionss" :key="subscription.id">
                                <div class="wrapper">
                                    <div class="thumb">
                                        <img :src="subscription.image" alt="default">
                                    </div>
                                    <div class="content">
                                        <h6 class="title">{{ subscription.title }}</h6>
                                        <p class="info">Buy it today</p>
                                        <router-link :to="{ path: '/subscriptions/' + subscription.id }" class="d-block"
                                            tabindex="0"><button>Buy now</button></router-link>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>

                    </div>
                    <div class="catagory_section">
                        <VueSlickCarousel class="slider-m-1" :arrows="false" :dots="false" v-bind="slickOptions">
                            <div class="wrapper">
                                <div class="thumb">
                                    <img src="@/assets/front/image/3.jpg" alt="">
                                </div>
                                <p class="info">Food & Drink</p>
                            </div>
                            <div class="wrapper">
                                <div class="thumb">
                                    <img src="@/assets/front/image/1.jpg" alt="">
                                </div>
                                <p class="info">Food & Drink</p>
                            </div>
                            <div class="wrapper">
                                <div class="thumb">
                                    <img src="@/assets/front/image/2.jpg" alt="">
                                </div>
                                <p class="info">Food & Drink</p>
                            </div>
                            <div class="wrapper">
                                <div class="thumb">
                                    <img src="@/assets/front/image/3.jpg" alt="">
                                </div>
                                <p class="info">Food & Drink</p>
                            </div>
                            <div class="wrapper">
                                <div class="thumb">
                                    <img src="@/assets/front/image/4.png" alt="">
                                </div>
                                <p class="info">Food & Drink</p>
                            </div>
                        </VueSlickCarousel>
                    </div>
                    <div class="related_products">
                        <h5 class="heading">Squad picks</h5>
                        <VueSlickCarousel class="slider-m-1" :arrows="false" :dots="false">
                            <div class="wrapper">
                                <div class="thumb">
                                    <img src="@/assets/front/image/3.jpg" alt="">
                                    <div class="content">
                                        <h6 class="title">Soul Senses Spa & Wellness</h6>
                                        <p class="info">Soul Senses Spa - powred by intro </p>
                                        <ul class="tag">
                                            <li>Buy 1 Get 1 Free</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="wrapper">
                                <div class="thumb">
                                    <img src="@/assets/front/image/1.jpg" alt="">
                                    <div class="content">
                                        <h6 class="title">Soul Senses Spa & Wellness</h6>
                                        <p class="info">Soul Senses Spa - powred by intro </p>
                                        <ul class="tag">
                                            <li>Buy 1 Get 1 Free</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="wrapper">
                                <div class="thumb">
                                    <img src="@/assets/front/image/4.png" alt="">
                                    <div class="content">
                                        <h6 class="title">Soul Senses Spa & Wellness</h6>
                                        <p class="info">Soul Senses Spa - powred by intro </p>
                                        <ul class="tag">
                                            <li>Buy 1 Get 1 Free</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="wrapper">
                                <div class="thumb">
                                    <img src="@/assets/front/image/2.jpg" alt="">
                                    <div class="content">
                                        <h6 class="title">Soul Senses Spa & Wellness</h6>
                                        <p class="info">Soul Senses Spa - powred by intro </p>
                                        <ul class="tag">
                                            <li>Buy 1 Get 1 Free</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>

                <!-- Footer Section -->
                <Front-Footer> </Front-Footer>

            </div>
        </div>

    </body>
</template>

<script>
import VueMeta from 'vue-meta'
import Header from './Front-Header';
import Footer from './Front-Footer';
import inspired from './common/Inspired';
import Subscription from './common/Subscription';
import features from './common/Features';
import OurApp from './common/OurApp';
import Newsletter from './common/Newsletter';
import Topintro from './common/Topintro';
import Loader from './Loader';
import Vue from 'vue'

import { db } from '@/main'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
Vue.use(VueMeta)


export default {
    components: {
        'Front-Header': Header,
        'Front-Footer': Footer,
        'Subscription': Subscription,
        'Inspired': inspired,
        'features': features,
        'our-app': OurApp,
        'newsletter': Newsletter,
        'topintro': Topintro,
        'Loader': Loader,
        'VueSlickCarousel': VueSlickCarousel

    },
    data() {
        return {
            components: {},
            pages: {},
            slickOptions: {
                //options can be used from the plugin documentation
                slidesToShow: 3,
                infinite: true,
                meta_title: '',
                meta_description: '',
            },
            subscriptionss: [],
        }
    },
    metaInfo() {
        //alert(this.meta_title);
        return {
            title: localStorage.getItem("meta_title"),
            meta: [
                { name: 'description', content: localStorage.getItem("meta_description") },
            ]
        }
    },
    created: function () {
        this.fetchComponents();
        this.fetchPages();
        this.fetchItems();

    },

    methods: {
        
        fetchItems() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.subscriptionss = localStorage.getItem("subscriptionss");
            // console.log(this.subscriptionss == null)
            if (this.subscriptionss == null) {
                this.subscriptionss = [];
                this.slug = this.$route.params.slug;

                db.collection("countries")
                    .where("slug", "==", this.slug)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((docs) => {

                            db.collection("subscriptions")
                                .where("country", "==", docs.id)
                                .get()
                                .then((querySnapshot) => {
                                    querySnapshot.forEach((doc) => {

                                        // console.log(doc.id, " => ", doc.data());

                                        this.subscriptionss.push({
                                            id: doc.id,
                                            title: doc.data().title,
                                            image: doc.data().image,
                                        });
                                        console.log(this.subscriptionss)
                                        localStorage.setItem("subscriptionss", JSON.stringify(this.subscriptionss));

                                    });
                                })
                                .catch((error) => {
                                    console.log("Error getting documents: ", error);
                                });

                        });
                    });

            } else {
                // alert('local')
                this.subscriptionss = JSON.parse(localStorage.getItem("subscriptionss"));
                console.log(this.subscriptionss)
            }


            db.collection("components").doc('nlXcHYzVkqDaWGJgixjF')
                .get()
                .then((doc) => {
                    this.components = doc.data();
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });


        },

        fetchComponents() {
            this.components = localStorage.getItem("components");
            // console.log(this.components == null)
            if (this.components == null) {
                this.components = {};
                db.collection("components").doc('ahotUyDbxXzk1h99c29N')
                    .get()
                    .then((doc) => {
                        // console.log('hhhh77');
                        // console.log(doc.id, " => ", doc.data());
                        this.components = doc.data();
                        console.log(this.components)
                        localStorage.setItem("components", JSON.stringify(this.components));
                    })
                    .catch((error) => {
                        console.log("Error getting documents: ", error);
                    });
            } else {
                // alert('local')
                this.components = JSON.parse(localStorage.getItem("components"));
                console.log(this.components)
            }


        },
        async fetchPages() {

            await db.collection("pages").doc('91T5r0xlt47gme91ObsZ')
                .get()
                .then(async (doc) => {
                    // console.log('hhhh88');
                    // console.log(doc.id, " => ", doc.data());
                    this.pages = doc.data();
                    this.meta_title = doc.data().meta_title;
                    this.meta_description = doc.data().meta_description;
                    localStorage.setItem("meta_title", this.meta_title);
                    localStorage.setItem("meta_description", this.meta_description);


                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
            // alert(this.meta_title);

        }
    }
}

</script>
<style>
.hot-picks-section {
    padding: 10vh 0;
}

.hot-picks-section .section-title {
    text-align: center;
    font-weight: 900;
    margin-bottom: 50px;
}

.hot-picks-section .Card {
    margin: 0 10px;
    position: relative;
}

.hot-picks-section .Card_info {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.hot-picks-section .Card img {
    border-radius: 8px;
    overflow: hidden;
    height: 400px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    object-fit: cover;
}

.hot-picks-section .Card .card-title {
    margin-top: 0;
    font-size: 20px;
    color: #fff;
    margin-bottom: 1px;
}

.hot-picks-section .Card:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    border-radius: 8px;
    background-image: linear-gradient(to top,
            #000000,
            #000000,
            #857d7d00,
            #b9b9b9,
            #ffffff00);
}

.hot-picks-section .Card .card-info {
    font-size: 14px;
    color: #fff;
    margin-bottom: 3px;
    margin-top: 0;
}

@media (max-width:767px) {
    section.hero_section.new-changes {
        height: 84vh;
    }

    section.hero_section img {
        max-height: initial !important;
        height: 100%;
        object-fit: cover;
    }

    section.hero_section.new-changes {
        margin: 0px 0px 0px 0px!important;
    }
}

.product_details div.top-content {
    height: 400px;
}

section.hero_section.new-changes {
    margin: 89px 0px 0px 0px;
}
</style>
