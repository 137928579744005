<template>
  <!-- Main Start -->

  <section class="newsletter">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-10">
          <div class="sib-form" style="text-align: center;
         background-color: transparent;">
            <div id="sib-form-container" class="sib-form-container">
              <div id="sib-container" class="sib-container--large sib-container--vertical"
                style="text-align:center; background-color:transparent; max-width:540px; border-radius:3px; border-width:0px; border-color:#C0CCD9; border-style:solid;">
                <validation-observer ref="simpleRules">
                  <form id="sib-form" v-on:submit.prevent="validationForm">
                    <!-- <form id="sib-form" method="POST"
                  action="https://09913da4.sibforms.com/serve/MUIEAAr2F2c-hdQzjdVoHyypEAfb0PU8PdoT4h511imDPS_4ctNlDrmhRjO9I2oYm1mskNmztrNlqeDT0Yu4MbxlK2vWicO86gqt_gTxiC21BATovvcRHE8jLRoeyX9UcOEMkHvZ7DKZfJC6Xa0FrjQl6JvzF-F4e2Ib7tH3JkiKRULoU5h1oLBNhmjlhT__8TVBeDnZznTXoqjd"> -->
                    <div style="padding: 8px 0;">
                      <div class="sib-form-block"
                        style="font-size:32px; text-align:left; font-weight:700;  color:#ffffff; background-color:transparent;">
                        <p style="color:#fff;"><strong>Subscribe to our Newsletter</strong></p>
                      </div>
                    </div>
                    <div style="padding: 8px 0;">
                      <div class="sib-form-block"
                        style="font-size:16px; text-align:left;  color:#ffffff; background-color:transparent;">
                        <div class="sib-text-form-block">
                          <p style="color:#fff;">Subscribe to our newsletter to receive exclusive offers, Latest news and
                            updates&nbsp;</p>
                        </div>
                      </div>
                    </div>
                    <div style="padding: 8px 0;">
                      <div class="sib-input sib-form-block">
                        <div class="form__entry entry_block">
                          <div class="form__label-row ">
                            <div class="entry__field">
                              <input class="input" type="email" v-model.trim="email" id="EMAIL" name="EMAIL" autocomplete="off"
                                placeholder="EMAIL" data-required="true" required />
                              <!-- <validation-provider name="Email Address" rules="required|email|max:50"
                                #default="{ errors }">
                                <input placeholder="Emal Address" type="text" class="input" v-model.trim="email"
                                  :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="padding: 8px 0;">
                      <div class="sib-form-block" style="text-align: left">
                        <button class="sib-form-block__button sib-form-block__button-with-loader"
                          style="font-size:16px; text-align:left; font-weight:700; font-family:&quot;Helvetica&quot;, sans-serif; color:#FFFFFF; background-color:#03e1bc; border-radius:3px; border-width:0px;"
                          form="sib-form" type="submit">
                          SUBSCRIBE
                        </button>
                      </div>
                    </div>
                    <input type="text" name="email_address_check" value="" class="input--hidden"
                      style="display:none!important">
                    <input type="hidden" name="locale" value="en">
                    <input type="hidden" name="html_type" value="simple">
                  </form>
                </validation-observer>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 text-center" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
          <svg width="397" height="332" viewBox="0 0 397 332" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path
                d="M322.469 313.626H81.4868L81.5955 288.034C81.8705 223.276 133.473 169.916 198.115 168.815C198.813 168.803 199.513 168.797 200.213 168.797H200.213C214.759 168.786 229.189 171.383 242.821 176.464C288.56 193.527 319.261 236.876 321.303 285.722L322.469 313.626Z"
                fill="white" />
              <path d="M397 313.626H0V314.621H397V313.626Z" fill="white" />
              <path
                d="M197.897 199.16L208.515 206.896L219.134 297.786H244.232L235.544 194.325L200.793 180.789L197.897 199.16Z"
                fill="#2F2E41" />
              <path
                d="M153.493 170.153C153.493 170.153 150.597 203.028 162.181 203.995C173.764 204.962 218.169 194.326 218.169 194.326L239.406 269.745L271.261 253.307C271.261 253.307 251.955 168.219 240.371 163.384C228.787 158.55 190.175 148.881 190.175 148.881L162.181 153.715L153.493 170.153Z"
                fill="#2F2E41" />
              <path
                d="M222.995 295.852C222.995 295.852 217.203 318.091 221.065 320.024C223.387 321.187 225.643 322.478 227.822 323.892V320.024C227.822 320.024 232.648 324.859 232.648 328.727C232.648 332.594 258.712 333.561 258.712 328.727C258.712 323.892 257.747 318.091 252.92 315.19C248.093 312.289 242.302 295.852 242.302 295.852H222.995Z"
                fill="#2F2E41" />
              <path d="M250.99 256.208L252.92 274.58L259.677 286.182L276.087 267.811L263.538 247.506L250.99 256.208Z"
                fill="#2F2E41" />
              <path
                d="M266.435 274.579C266.435 274.579 269.989 262.406 273.521 263.658C277.053 264.91 290.567 277.48 290.567 277.48L300.221 283.282C300.221 283.282 319.527 285.215 313.735 291.017C307.943 296.818 286.706 304.554 277.053 300.686C267.4 296.818 265.469 295.852 265.469 295.852L262.573 297.785L250.99 294.885L252.92 274.579C252.92 274.579 255.816 283.282 266.435 274.579Z"
                fill="#2F2E41" />
              <path
                d="M186.313 49.2884C198.575 49.2884 208.516 39.3316 208.516 27.0493C208.516 14.7671 198.575 4.8103 186.313 4.8103C174.051 4.8103 164.111 14.7671 164.111 27.0493C164.111 39.3316 174.051 49.2884 186.313 49.2884Z"
                fill="#9F616A" />
              <path
                d="M172.316 40.1027C172.316 40.1027 174.247 60.4079 168.455 60.4079C162.663 60.4079 169.42 85.5477 184.865 85.5477C200.31 85.5477 206.102 55.5734 206.102 55.5734C206.102 55.5734 195.484 51.7057 197.414 39.1358C199.345 26.5659 172.316 40.1027 172.316 40.1027Z"
                fill="#9F616A" />
              <path
                d="M170.778 58.859L162.663 62.3418L140.639 71.6749C139.752 72.0512 139.009 72.7049 138.522 73.5385C138.035 74.3721 137.83 75.341 137.938 76.3007L146.253 150.331C146.253 150.331 137.565 168.702 151.079 169.669L153.01 176.438L168.455 158.066L226.374 156.132L227.339 62.3418L200.838 51.8523C200.838 51.8523 197.234 71.8138 170.778 58.859Z"
                fill="#3F3D56" />
              <path
                d="M143.839 74.4282L140.272 73.4073C139.872 73.2928 139.452 73.2646 139.041 73.3246C138.629 73.3846 138.235 73.5314 137.884 73.7553C137.533 73.9791 137.234 74.275 137.005 74.6233C136.777 74.9717 136.625 75.3645 136.56 75.776L127.094 135.254C126.355 139.659 127.062 144.185 129.108 148.153C132.033 153.7 138.108 158.731 150.597 152.748L159.284 89.8988L143.839 74.4282Z"
                fill="#3F3D56" />
              <path
                d="M216.238 60.8914L227.822 62.8252L259.677 107.303L262.573 115.039L280.914 109.237C280.914 109.237 279.949 117.939 289.602 116.972C289.602 116.972 278.018 148.881 253.885 139.211C229.752 129.542 222.03 100.535 222.03 100.535L216.238 60.8914Z"
                fill="#3F3D56" />
              <path d="M229.753 80.2298L186.313 62.825L184.383 68.6266L204.654 86.031L229.753 80.2298Z" fill="#CCCCCC" />
              <path
                d="M172.946 28.4669C173.766 28.2476 173.886 27.1571 173.914 26.3074C174.061 21.7573 176.796 17.0883 181.145 15.7636C182.813 15.3053 184.56 15.209 186.269 15.4812C188.708 15.8066 191.063 16.5936 193.209 17.8002C194.341 18.438 195.452 19.2061 196.737 19.4009C197.585 19.5296 201.258 20.5994 202.103 20.75C203.96 21.081 205.687 22.7455 207.469 22.1261C209.174 21.5335 209.552 19.3221 209.593 17.5147C209.687 13.3972 206.566 7.69833 203.817 4.63519C201.731 2.31069 198.591 1.23766 195.521 0.679713C191.934 0.102492 188.3 -0.118975 184.67 0.0185303C179.757 0.106304 174.707 0.486168 170.277 2.61564C165.846 4.74511 162.125 8.98327 161.912 13.9009C161.868 14.9253 161.974 15.954 161.888 16.9757C161.678 19.4682 160.356 21.7196 159.737 24.1426C159.293 25.9205 159.227 27.7717 159.542 29.5768C159.857 31.3819 160.546 33.1008 161.565 34.6228C163.209 37.0466 169.785 39.247 169.69 42.1758C170.878 40.9426 168.075 39.7011 169.264 38.4678C169.796 38.0605 170.173 37.4826 170.332 36.8306C170.49 36.1785 170.421 35.4917 170.134 34.8848L168.873 29.9543C168.641 29.0515 168.414 28.0743 168.763 27.2104C170.116 23.8619 171.579 28.8326 172.946 28.4669Z"
                fill="#2F2E41" />
              <path
                d="M184.383 68.6266L166.042 140.178L237.475 165.318L278.984 130.509L305.047 52.1892L262.573 83.1304L184.383 68.6266Z"
                fill="#F2F2F2" />
              <path d="M187.884 76.378L187.638 77.3127L257.141 95.6841L257.387 94.7494L187.884 76.378Z" fill="#E6E6E6" />
              <path d="M185.954 81.2127L185.708 82.1475L255.21 100.519L255.456 99.5841L185.954 81.2127Z" fill="#E6E6E6" />
              <path d="M184.988 86.0472L184.742 86.9819L254.245 105.353L254.491 104.419L184.988 86.0472Z"
                fill="#E6E6E6" />
              <path d="M184.023 90.8819L183.777 91.8167L253.279 110.188L253.526 109.253L184.023 90.8819Z"
                fill="#E6E6E6" />
              <path d="M183.058 95.7164L182.812 96.6511L252.314 115.023L252.56 114.088L183.058 95.7164Z" fill="#E6E6E6" />
              <path d="M182.092 100.551L181.846 101.486L251.349 119.857L251.595 118.922L182.092 100.551Z"
                fill="#E6E6E6" />
              <path d="M181.127 105.386L180.881 106.32L250.384 124.692L250.63 123.757L181.127 105.386Z" fill="#E6E6E6" />
              <path d="M180.162 110.22L179.916 111.155L249.418 129.526L249.665 128.591L180.162 110.22Z" fill="#E6E6E6" />
              <path d="M179.197 115.055L178.95 115.989L248.453 134.361L248.699 133.426L179.197 115.055Z" fill="#E6E6E6" />
              <path
                d="M154.949 121.259C154.949 121.259 176.66 108.27 186.313 116.972C195.967 125.675 158.319 138.245 158.319 138.245L154.949 121.259Z"
                fill="#9F616A" />
              <path
                d="M131.29 127.608L159.285 116.972C159.285 116.972 154.458 135.344 164.111 136.311L150.597 152.748C150.597 152.748 123.568 152.748 131.29 127.608Z"
                fill="#3F3D56" />
              <path d="M204.956 127.212L242.103 139.752L233.312 158.946L196.165 146.406L204.956 127.212Z"
                fill="#E6E6E6" />
              <path d="M270.778 86.5146L298.773 65.2422L291.05 91.3493L262.091 112.621L270.778 86.5146Z" fill="#CCCCCC" />
              <path d="M284.967 100.633L256.972 121.905L257.555 122.675L285.55 101.404L284.967 100.633Z" fill="#E6E6E6" />
              <path d="M283.036 111.269L255.041 132.541L255.625 133.311L283.619 112.04L283.036 111.269Z" fill="#E6E6E6" />
              <path
                d="M286.706 117.939C286.706 117.939 310.839 103.435 295.394 97.6339C279.949 91.8325 279.949 114.071 279.949 114.071C279.949 114.071 278.983 118.906 286.706 117.939Z"
                fill="#9F616A" />
              <path opacity="0.1" d="M145.288 85.5479L144.322 123.258L159.285 118.98L147.218 119.39L145.288 85.5479Z"
                fill="white" />
              <path
                d="M39.9528 296.572C42.9703 307.746 53.3062 314.665 53.3062 314.665C53.3062 314.665 58.7497 303.47 55.7321 292.297C52.7145 281.123 42.3786 274.203 42.3786 274.203C42.3786 274.203 36.9352 285.399 39.9528 296.572Z"
                fill="#8985A8" />
              <path
                d="M44.3868 294.171C52.6671 302.245 53.6784 314.657 53.6784 314.657C53.6784 314.657 41.2648 313.978 32.9845 305.904C24.7042 297.83 23.6929 285.418 23.6929 285.418C23.6929 285.418 36.1065 286.097 44.3868 294.171Z"
                fill="#3F3D56" />
              <path d="M325.947 188.705H311.041V203.636H325.947V188.705Z" fill="#F2F2F2" />
              <path d="M266.323 19.4885H251.417V34.4194H266.323V19.4885Z" fill="#F2F2F2" />
              <path
                d="M79.0024 170.788C83.1186 170.788 86.4555 167.445 86.4555 163.322C86.4555 159.199 83.1186 155.857 79.0024 155.857C74.8862 155.857 71.5493 159.199 71.5493 163.322C71.5493 167.445 74.8862 170.788 79.0024 170.788Z"
                fill="#F2F2F2" />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="397" height="332" fill="white" />
              </clipPath>
            </defs>
          </svg>

        </div>
      </div>
    </div>
  </section>

  <!-- Main End -->
</template>



<script>
import { db } from "@/main";
import firebase from "firebase";
import axios from 'axios';
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Vee-Validate 
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
  required, email, confirmed, password, image, size
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    validate,
    required,
    email
  },
  data() {
    return {
      item: {},
      itemde: {},
      email: '',
    }
  },

  created: function () {
    this.fetchFeatures();
  },

  methods: {

    fetchFeatures() {

      db.collection("psetings").doc('ypstkkwhyrS9AOfp55ej')
        .get()
        .then((doc) => {
          // console.log('hhhh11');
          // console.log(doc.id, " => ", doc.data());
          this.item = doc.data();

        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      db.collection("psetings").doc('mqCcfwiqGvPk5VOfuFzo')
        .get()
        .then((doc) => {
          // console.log('hhhh11');
          // console.log(doc.id, " => ", doc.data());
          this.itemde = doc.data();

        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });


    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submit();
        }
      })
    },

    submit() {
      // alert('testsfljds')
      var uid = localStorage.getItem("userSessionId");
      var username = localStorage.getItem("userFirstname");
      // console.log(uid)
      if(uid == null){
        uid = "";
        username = ""
      }
      store.dispatch('app/commitActivateLoader');
      var date = new Date().toLocaleString()

      db.collection("subscribers")
        .add({
          user_id: uid,
          username: username,
          email: this.email,
          created: date,
          modified: date,
        })
        .then(() => {
          store.dispatch('app/commitDeactivateLoader');
          this.$router.push('/thankyou');
          console.log("Added Successfully!");
          this.showNotification('Success, Thank you for contact!', 'AlertTriangleIcon', 'success');


          // var axios = require('axios');
          // var FormData = require('form-data');
          // var data = new FormData();
          // data.append(' first_name', this.first_name);
          // data.append('last_name', this.last_name);
          // data.append('email', this.email);
          // data.append('emailm', this.emailm);
          // data.append('phone_no', this.phone_no);
          // data.append('subject', this.emailname);
          // data.append('message', this.message);

          // var config = {
          //   method: 'post', url: this.$VUE_APP_API_ENDPOINT + 'conatct_us', headers: { 'Content-Type': 'multipart/form-data' }, data: data
          // };

          // axios(config)
          //   .then(function (response) {
          //     console.log(JSON.stringify(response.data));
          //   })
          //   .catch(function (error) {
          //     console.log(error);
          //   });


        })
        .catch((error) => {
          console.error("Error writing document: ", error);
          store.dispatch('app/commitDeactivateLoader');
        });


    },

    showNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  }
}

</script>
<style>
#sib-container input:-ms-input-placeholder {
  text-align: left;
  color: #c0ccda;
}

#sib-container input::placeholder {
  text-align: left;
  color: #c0ccda;
}
</style>


